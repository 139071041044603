"use client";

import React from "react";

import Image from "next/image";
import Link from "next/link";
import { Routes } from "~/src/app/_constants/routes";
import {
  CREATE_WORKSPACE_BUTTON_TITLE,
  CREATE_WORKSPACE_BUTTON_ALT,
  CREATE_WORKSPACE_BUTTON_ICON,
  CREATE_WORKSPACE_BUTTON_WIDTH,
  CREATE_WORKSPACE_BUTTON_HEIGHT,
} from ".";

export const CreateWorkspaceButton = () => {
  return (
    <div className="group flex h-10 items-center gap-2 rounded-xl px-2 text-sm font-medium text-neutral-primary transition-colors duration-150 hover:bg-button-brand-primary-light-hover active:bg-button-brand-primary-light-hover">
      <Link
        href={Routes.workspace.create}
        title={CREATE_WORKSPACE_BUTTON_TITLE}
      >
        <Image
          src={CREATE_WORKSPACE_BUTTON_ICON}
          alt={CREATE_WORKSPACE_BUTTON_ALT}
          width={CREATE_WORKSPACE_BUTTON_WIDTH}
          height={CREATE_WORKSPACE_BUTTON_HEIGHT}
          priority
        />
      </Link>
    </div>
  );
};
