import { Routes } from "src/app/_constants/routes";

export const EMPLOYER_NAV_LINKS = [
  { label: "Dashboard", href: Routes.workspace.dashboard },
  { label: "Messaging", href: Routes.company.messaging },
];

export const JOBSEEKER_NAV_LINKS = [
  { label: "Board", href: Routes.jobs_view },
  { label: "My Jobs", href: Routes.jobs_view },
  { label: "Messaging", href: Routes.jobseeker.messaging },
  { label: "Profile", href: Routes.jobseeker.profile },
];

export const LOGGED_OUT_NAV_LINKS = [
  { label: "Home", href: Routes.home },
  { label: "Jobs", href: Routes.jobs_view },
  { label: "Contact", href: Routes.contact },
];

export interface NavLink {
  label: string;
  href: string;
}
