import Link from "next/link";
import React from "react";
import { useSession } from "next-auth/react";
import { useNavbarContext } from "../../navbar-context";
import { useRouter } from "next/navigation";

export const WorkspacesDropdownMenuFooter: React.FC = () => {
  const router = useRouter();
  const { setCurrentWorkspace } = useNavbarContext();
  const { update } = useSession();

  const handleSwitchToJobSeeker = async () => {
    await update({ subdomain: null });
    setCurrentWorkspace(null);
    await router.push("/jobseeker/dashboard");
  };

  return (
    <Link
      href="#"
      onClick={handleSwitchToJobSeeker}
      className="flex w-full items-center justify-between gap-1 px-4 py-2 text-brand-primary-rest hover:cursor-pointer"
    >
      <div className="flex items-center gap-2 text-sm font-medium">
        Switch to Job Seeker
      </div>
      <i className="fa-regular fa-arrow-right-from-bracket" />
    </Link>
  );
};
