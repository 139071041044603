import { handleLogout } from "@auth0/nextjs-auth0";
import { deleteCookie } from "cookies-next";
import { signOut } from "next-auth/react";
import Link from "next/link";
import React from "react";

export const AccountDropdownMenuFooter: React.FC = ({}) => {
  const handleLogout = async () => {
    deleteCookie("access_token");
    deleteCookie("refresh_token");

    await signOut({
      callbackUrl: "/",
    });
  };

  return (
    <div className="flex w-full justify-between p-4">
      <Link
        className="flex cursor-pointer items-center gap-2 text-sm font-medium text-brand-primary-rest"
        href="/public/pp"
      >
        Privacy Policy
      </Link>
      <Link
        className="flex cursor-pointer items-center gap-2 text-sm font-medium text-brand-primary-rest"
        onClick={e => {
          e.preventDefault();
          handleLogout();
        }}
        href="/api/auth/logout"
      >
        Log Out
        <i className="fa-regular fa-arrow-right-from-bracket" />
      </Link>
    </div>
  );
};
