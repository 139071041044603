import React, { PropsWithChildren } from "react";
import { Divider } from "~/scalis-components/core/divider";
import {
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from "~/scalis-components/core/dropdown-menu";
import { WorkspaceItem } from "../workspace-item";
import { WorkspacesDropdownSubmenuFooter } from "./footer";
import { WorkspacesDropdownSubmenuContent } from "./content";

import { useNavbarContext } from "../../../navbar-context/navbar-context";

export const WorkspacesDropdownSubmenu: React.FC<PropsWithChildren> = () => {
  const { workspaces, currentWorkspace } = useNavbarContext();

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger hideTrigger={true}>
        <WorkspaceItem />
      </DropdownMenuSubTrigger>

      {currentWorkspace && !!workspaces?.length && (
        <DropdownMenuSubContent
          sideOffset={5}
          alignOffset={-38}
          className="w-full rounded-xl bg-white p-0"
        >
          <WorkspacesDropdownSubmenuContent />

          <Divider />

          <WorkspacesDropdownSubmenuFooter />
        </DropdownMenuSubContent>
      )}
    </DropdownMenuSub>
  );
};
