"use client";

import { AvatarBase } from "~/scalis-components/core/avatar";
import { getInitials } from "~/utils/getInitials";
import { avatarClassName } from "./company-avatar.styles";
import { CompanyAvatarProps } from "./company-avatar.types";

export const CompanyAvatar = ({
  imageSrc = "",
  name,
  size,
}: CompanyAvatarProps) => {
  const initials = getInitials(name);

  const { base, avatarStyles, fallbackStyles } = avatarClassName();

  return (
    <AvatarBase.AvatarContainer className={base({ size })}>
      <AvatarBase.AvatarBase className={avatarStyles()}>
        <AvatarBase.AvatarImage src={imageSrc} alt={name} />
        <AvatarBase.AvatarFallback className={fallbackStyles()}>
          {initials}
        </AvatarBase.AvatarFallback>
      </AvatarBase.AvatarBase>
    </AvatarBase.AvatarContainer>
  );
};
