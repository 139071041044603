"use client";

import { CompanyAvatar } from "~/scalis-components/core/company-avatar";

import React from "react";
import { cn } from "~/utils/cn";
import { WorkspaceButtonProps } from "./workspace-button.types";
import { getWorkspace } from "./workspace-button.utils";
import { useNavbarContext } from "../../../navbar-context";

export const WorkspaceButton = ({ isOpen }: WorkspaceButtonProps) => {
  const { workspaces, currentWorkspace } = useNavbarContext();

  if (!currentWorkspace || !workspaces) {
    return null;
  }

  const workspaceInfo = getWorkspace(currentWorkspace.id, workspaces);

  return (
    <div className="group flex h-10 items-center gap-2 rounded-xl border border-neutral-10 p-1.5 text-sm font-medium text-neutral-primary transition-colors duration-150 hover:bg-button-brand-primary-light-hover active:bg-button-brand-primary-light-hover">
      <CompanyAvatar
        imageSrc={workspaceInfo?.imageSrc ?? ""}
        name={workspaceInfo?.subdomain!}
        size="xs"
      />
      <span className="hidden md:block">{workspaceInfo?.subdomain}</span>
      <i
        aria-label="icon workspace menu"
        className={cn("fa-solid fa-caret-down", { "fa-rotate-180": isOpen })}
      />
    </div>
  );
};
