import React, { useEffect } from "react";
import useBreakpoint from "~/hooks/useBreakpoint";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "~/scalis-components/core/dropdown-menu";
import { Drawer } from "../../drawer";
import { WorkspaceButton } from "./components/workspace-button";
import { WorkspacesDropdownMenuProps } from "./workspaces-dropdown-menu.types";
import {
  WorkspacesDropdownSubmenuFooter,
  WorkspacesDropdownSubmenuContent,
  WorkspacesDropdownMenuFooter,
  WorkspacesDropdownMenuContent,
} from "./components";
import { Divider } from "../../divider";

export const WorkspacesDropdownMenu: React.FC<WorkspacesDropdownMenuProps> = ({
  companyId,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);
  const { lg } = useBreakpoint();

  return (
    <div className="flex w-full items-center justify-center">
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger
          aria-label="Workspace Button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <WorkspaceButton />
        </DropdownMenuTrigger>

        {lg ? (
          <DropdownMenuContent
            className="w-64 rounded-xl bg-white p-0"
            align="start"
            collisionPadding={10}
          >
            <WorkspacesDropdownMenuContent companyId={companyId} />
            <Divider />

            <WorkspacesDropdownMenuFooter />
          </DropdownMenuContent>
        ) : (
          <>
            <Drawer
              isOpen={isOpen}
              onOpenChange={setIsOpen}
              title={""}
              contentClassName="px-0"
              footerContent={<WorkspacesDropdownMenuFooter />}
            >
              <WorkspacesDropdownMenuContent
                companyId={companyId}
                onOpenSubMenu={setIsSubMenuOpen}
              />
            </Drawer>
            <Drawer
              isOpen={isSubMenuOpen}
              onOpenChange={setIsSubMenuOpen}
              title={""}
              contentClassName="px-0"
              footerContent={<WorkspacesDropdownSubmenuFooter />}
            >
              <>
                <div
                  onClick={() => {
                    setIsSubMenuOpen(false);
                  }}
                  className="mt-[-1rem] flex cursor-pointer items-center gap-2 p-2 text-sm font-medium text-brand-primary-rest"
                >
                  <i className="fa-solid fa-chevron-left"></i>
                  Back
                </div>
                <Divider />
                <WorkspacesDropdownSubmenuContent />
              </>
            </Drawer>
          </>
        )}
      </DropdownMenu>
    </div>
  );
};
