"use client";

import React from "react";
import { useSession } from "next-auth/react";
import { usePathname } from "next/navigation";
import { isEmpty } from "lodash";

import { AccountDropdownMenu } from "~/scalis-components/core/navbar/account-dropdown-menu/account-dropdown-menu";
import { NavBarTemplate } from "~/scalis-components/core/navbar/navbar-template/navbar-template";
import { SearchMenu } from "~/scalis-components/core/navbar/search-menu/search-menu";
import { WorkspacesDropdownMenu } from "~/scalis-components/core/navbar/workspaces-dropdown-menu";
import { CreateWorkspaceButton } from "~/scalis-components/core/navbar/workspaces-dropdown-menu/components/create-workspace-button/create-workspace-button";

import { useNavLinks } from "./navbar-default.hooks";
import { useNavbarContext } from "~/scalis-components/core/navbar/navbar-context";
import { Routes } from "~app/_constants/routes";

export const NavbarDefault = ({ className }: { className?: string }) => {
  const { data: session, status } = useSession();
  const isSignedIn = status === "authenticated";
  const isLoading = status === "loading";
  const pathname = usePathname();
  const navLinks = useNavLinks();
  const { currentWorkspace, userRole } = useNavbarContext();

  const isJobseeker = userRole === "JobSeeker";
  const hasWorkspaceSelected = isSignedIn && !isEmpty(currentWorkspace);

  const isCompanyWorkspaceRoute =
    pathname?.startsWith("/company") ||
    companyPaths.includes(pathname as (typeof companyPaths)[number]);
  const isGeneralScalisRoute = generalPaths.includes(
    pathname as (typeof generalPaths)[number],
  );
  const showCompanyNavBar =
    isCompanyWorkspaceRoute && userRole === "CompanyWorkspace";
  const showGeneralNavBar = isGeneralScalisRoute && userRole === "JobSeeker";

  const searchInput = isSignedIn ? <SearchMenu /> : null;

  const AccountButton: React.FC = () => {
    if (!isSignedIn) return null;
    return (
      <AccountDropdownMenu
        isJobSeeker={isJobseeker}
        email={session?.user?.email!}
        imageSrc={session?.user?.image!}
        name={session?.user?.name!}
        userId={Number(session?.user?.id!)}
      />
    );
  };

  const workspaceButton = hasWorkspaceSelected ? (
    <WorkspacesDropdownMenu companyId={currentWorkspace?.id} />
  ) : (
    <CreateWorkspaceButton />
  );

  return (
    <NavBarTemplate
      isCompanyNav={showCompanyNavBar}
      isGeneralNav={showGeneralNavBar}
      isLogoOnly={isLoading}
      className={className}
      isLoggedOut={!isSignedIn}
      accountButton={<AccountButton />}
      navLinks={navLinks}
      menuButton={workspaceButton}
      searchInput={searchInput}
    />
  );
};

const companyPaths = [
  Routes.company.dashboard,
  Routes.company.active,
  Routes.company.messaging,
  Routes.company.profile,
  Routes.company.talent_pool,
  Routes.company.settings.general.company_information,
  Routes.company.settings.general.offices,
  Routes.company.settings.general.departments,
  Routes.company.settings.user_management,
  Routes.company.settings.calendar_settings,
  Routes.company.settings.email_templates,
  Routes.company.settings.career_page_setup,
  Routes.company.settings.billing,
  Routes.company.settings.security,
  Routes.company.settings.integrations,
  Routes.requisition.new,
  Routes.requisition.jobDetails,
  Routes.requisition.jobDescription,
  Routes.requisition.skillsAndPreferences,
  Routes.requisition.listings,
  Routes.requisition.pipelineSetup,
  Routes.requisition.postJob,
  Routes.requisition.requisitionDetails,
];

const generalPaths = [
  Routes.home,
  Routes.contact,
  Routes.user.profile,
  Routes.user.settings.account_information,
  Routes.user.settings.sign_in_and_security,
  Routes.application_flow.name_resume,
  Routes.application_flow.basic_information,
  Routes.application_flow.profile,
  Routes.application_flow.screening_questions,
  Routes.jobs_view,
];
