import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";

export const avatarClassName = tv({
  base: cn(
    "flex items-center flex-shrink-0 uppercase box-border border overflow-hidden",
    "border-neutral-00 justify-center font-medium text-neutral-inverse-dark bg-neutral-20 rounded-xl",
  ),
  slots: {
    avatarStyles: "w-full h-full rounded-none",
    fallbackStyles: "w-full h-full rounded-none",
  },
  variants: {
    size: {
      xs: "w-7 h-7 text-[0.5rem] rounded-lg",
      sm: "w-8 h-8 text-xs",
      md: "w-10 h-10 text-xs",
      lg: "w-12 h-12 text-sm",
      xl: "w-14 h-14 text-sm",
      "2xl": "w-[72px] h-[72px] text-lg",
      "3xl": "w-[88px] h-[88px] text-lg",
    },
  },
  defaultVariants: {
    size: "md",
  },
});
