import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "~/scalis-components/core/dropdown-menu";
import { Divider } from "../../divider";
import { AccountDropdownMenuProps } from "./account-dropdown-menu.types";
import {
  AccountButton,
  AccountDropdownMenuContent,
  AccountDropdownMenuFooter,
} from "./components";
import useBreakpoint from "~/hooks/useBreakpoint";
import { Drawer } from "../../drawer";

export const AccountDropdownMenu: React.FC<AccountDropdownMenuProps> = ({
  imageSrc,
  name,
  email,
  userId,
  backgroundColor,
  isJobSeeker,
}) => {
  const { lg } = useBreakpoint();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="flex items-center justify-center">
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger>
          <AccountButton
            name={name}
            imageSrc={imageSrc}
            avatarClassName="w-7 h-7"
            onClick={() => setIsOpen(!isOpen)}
            backgroundColor={backgroundColor}
          />
        </DropdownMenuTrigger>
        {lg ? (
          <DropdownMenuContent
            className="w-64 rounded-xl bg-white p-0"
            collisionPadding={10}
          >
            <AccountDropdownMenuContent
              {...{ imageSrc, name, email, userId, isJobSeeker }}
            />
            <Divider />
            <AccountDropdownMenuFooter />
          </DropdownMenuContent>
        ) : (
          <Drawer
            isOpen={isOpen}
            onOpenChange={setIsOpen}
            title={""}
            contentClassName="px-0"
            footerContent={<AccountDropdownMenuFooter />}
          >
            <AccountDropdownMenuContent
              {...{ imageSrc, name, email, userId, isJobSeeker }}
            />
            <Divider />
          </Drawer>
        )}
      </DropdownMenu>
    </div>
  );
};
