import React, { PropsWithChildren } from "react";
import { CompanyAvatar } from "~/scalis-components/core/company-avatar";
import { Divider } from "~/scalis-components/core/divider";
import { Body } from "~/scalis-components/core/typography";
import { useNavbarContext } from "../../../navbar-context/navbar-context";
import { getWorkspace } from "../workspace-button";
import { cn } from "~/utils/cn";

export const WorkspacesDropdownSubmenuContent: React.FC<
  PropsWithChildren
> = () => {
  const { workspaces, currentWorkspace, setCurrentWorkspace } =
    useNavbarContext();

  if (!workspaces || !currentWorkspace) return null;

  return (
    <>
      <div className="p-2">
        {workspaces.map(item => {
          const isCurrentWorkspace = item.id === currentWorkspace.id;
          const { imageSrc, name } =
            getWorkspace(item.id, workspaces) ?? workspaces[0];

          return (
            <div
              key={item.id}
              className={cn(
                "flex w-full cursor-pointer items-center justify-between gap-2 rounded-xl p-2 hover:cursor-pointer hover:bg-neutral-20",
                isCurrentWorkspace && "bg-neutral-20",
              )}
              aria-label={item.name}
              onClick={() => {
                setCurrentWorkspace(item);
              }}
            >
              <div className="flex gap-2">
                <CompanyAvatar imageSrc={imageSrc} name={name} />
                <div className="flex flex-col">
                  <Body>{item.subdomain}</Body>
                  <span className="text-sm uppercase leading-4 text-neutral-secondary">
                    {item.id}
                  </span>
                </div>
              </div>
              {isCurrentWorkspace && (
                <i className="fa-solid fa-circle-dot px-4 py-2" />
              )}
            </div>
          );
        })}
      </div>
      <Divider />
    </>
  );
};
