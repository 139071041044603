import { useSession } from "next-auth/react";

import {
  NavLink,
  EMPLOYER_NAV_LINKS,
  JOBSEEKER_NAV_LINKS,
  LOGGED_OUT_NAV_LINKS,
} from "./navbar-default.constants";

export const useNavLinks = (): NavLink[] => {
  const { data: session, status } = useSession();
  const isSignedIn = status === "authenticated";

  if (!isSignedIn) {
    return LOGGED_OUT_NAV_LINKS;
  }

  if (session.user.workspace?.currentCompany) {
    return EMPLOYER_NAV_LINKS;
  }

  return JOBSEEKER_NAV_LINKS;
};
